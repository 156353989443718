import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M850 2467 c-4 -225 2 -921 8 -920 4 1 40 20 80 42 39 23 76 41 82 41
11 0 15 456 4 484 -17 45 -24 -17 -24 -224 l0 -229 -25 -19 c-31 -24 -84 -47
-92 -39 -7 8 -8 796 -1 803 5 5 49 -18 118 -61 19 -12 80 -48 135 -80 55 -32
143 -85 196 -117 128 -77 137 -80 179 -59 54 27 254 143 430 249 19 12 56 33
82 46 44 24 47 25 53 7 11 -35 2 -120 -14 -135 -17 -15 -101 -64 -221 -128
-41 -22 -106 -59 -145 -81 -38 -22 -83 -47 -100 -55 -16 -9 -46 -26 -65 -39
-19 -13 -41 -23 -48 -23 -12 0 -199 101 -332 180 -30 18 -68 37 -85 41 -29 9
-29 9 -10 -10 20 -20 183 -116 342 -201 67 -36 86 -43 100 -34 20 12 372 209
418 234 17 9 65 35 107 59 l77 42 3 110 c2 60 0 109 -3 109 -4 0 -29 -14 -55
-31 -27 -17 -130 -79 -229 -136 -99 -58 -214 -126 -255 -151 -41 -25 -80 -45
-85 -45 -6 0 -39 18 -75 40 -36 22 -132 79 -215 128 -216 127 -298 177 -317
193 -10 8 -18 12 -18 9z"/>
<path d="M2076 2151 c-6 -9 -56 -38 -371 -216 -104 -59 -224 -127 -265 -150
-111 -63 -143 -81 -275 -155 -66 -37 -143 -80 -170 -96 -28 -16 -72 -40 -98
-54 l-47 -25 0 -102 c0 -64 4 -103 10 -103 23 0 205 103 586 331 l30 18 225
-126 c123 -69 262 -147 309 -173 l85 -47 2 121 c9 437 7 786 -4 786 -6 0 -14
-4 -17 -9z m0 -449 c1 -326 -1 -392 -13 -392 -12 0 -133 63 -248 130 -195 113
-320 180 -334 180 -19 0 -127 -60 -341 -188 -165 -100 -242 -142 -255 -142 -3
0 -5 35 -3 77 l3 78 45 24 c25 13 115 63 200 111 85 48 178 100 205 115 121
67 147 82 265 150 69 39 157 89 195 111 39 21 79 44 90 51 11 7 29 16 41 22
11 5 42 23 70 40 84 54 79 77 80 -367z"/>
<path d="M1820 1813 c-36 -20 -77 -42 -91 -50 -29 -15 -55 -41 -47 -48 6 -6
192 97 213 118 28 28 -4 20 -75 -20z"/>
<path d="M1920 1693 c0 -87 3 -123 10 -113 13 20 13 240 0 240 -6 0 -10 -49
-10 -127z"/>
<path d="M1700 1665 c13 -14 27 -25 31 -25 4 0 34 -15 66 -34 33 -19 72 -38
89 -41 l29 -7 -34 25 c-35 27 -183 107 -197 107 -4 0 3 -11 16 -25z"/>
<path d="M880 1125 c0 -96 3 -115 15 -115 12 0 15 18 15 100 0 89 9 123 23 88
7 -19 6 -33 -2 -31 -5 1 -1 -32 8 -73 13 -66 18 -74 38 -74 23 0 32 21 49 111
8 42 22 69 34 69 13 0 13 -33 -1 -47 -6 -6 -11 -39 -12 -73 -2 -62 -2 -63 26
-64 l27 -2 0 113 0 113 -38 0 -39 0 -18 -80 c-20 -89 -42 -122 -32 -49 3 24 1
63 -5 87 -10 40 -12 42 -49 42 l-39 0 0 -115z"/>
<path d="M1645 1130 l0 -110 33 0 c33 0 34 0 30 42 -2 36 0 43 17 46 53 8 63
14 75 41 9 23 9 34 -1 53 -18 34 -28 38 -94 38 l-60 0 0 -110z m98 59 c6 -24
-1 -49 -14 -49 -5 0 -12 -1 -16 -2 -4 -2 -8 14 -8 35 -1 29 2 37 15 37 10 0
20 -9 23 -21z"/>
<path d="M1157 1180 c-47 -38 -44 -122 7 -156 20 -12 34 -14 63 -7 46 11 56
33 15 33 -31 0 -68 32 -57 49 3 6 24 11 46 11 37 0 39 2 39 29 0 55 -65 79
-113 41z m63 -30 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20
13 0 20 -7 20 -20z"/>
<path d="M1321 1183 c-28 -23 -32 -70 -7 -88 15 -11 15 -14 1 -25 -9 -7 -13
-21 -10 -30 3 -11 -1 -20 -10 -24 -45 -17 7 -66 71 -66 57 0 96 31 92 74 -3
31 -4 31 -59 31 -41 0 -55 3 -52 13 3 6 17 12 33 12 32 0 60 28 60 59 0 16 4
21 15 17 10 -4 15 1 15 14 0 13 -7 20 -19 20 -10 0 -38 3 -62 6 -35 5 -49 2
-68 -13z m65 -39 c7 -27 -7 -50 -25 -40 -6 4 -11 20 -11 37 0 37 27 40 36 3z
m22 -141 c-2 -13 -13 -18 -38 -18 -25 0 -36 5 -38 18 -3 14 4 17 38 17 34 0
41 -3 38 -17z"/>
<path d="M1508 1193 c-34 -9 -20 -30 20 -29 48 1 51 -25 4 -35 -44 -9 -55 -21
-55 -60 0 -34 27 -63 51 -54 8 3 31 5 53 5 l39 0 0 74 c0 54 -4 79 -16 90 -15
15 -58 19 -96 9z m62 -114 c0 -24 -4 -30 -17 -27 -22 4 -30 38 -11 49 22 15
28 10 28 -22z"/>
<path d="M1838 1193 c-34 -9 -20 -30 18 -29 22 1 39 -4 41 -11 5 -13 -2 -17
-48 -27 -32 -7 -52 -47 -40 -80 12 -29 23 -33 89 -28 l52 4 0 73 c0 54 -4 78
-16 89 -15 15 -58 19 -96 9z m62 -114 c0 -24 -4 -30 -17 -27 -22 4 -30 38 -11
49 22 15 28 10 28 -22z"/>
<path d="M1960 1198 c0 -2 15 -44 34 -95 36 -98 34 -123 -9 -123 -16 0 -25 -6
-25 -15 0 -19 63 -21 77 -2 5 6 17 32 26 57 9 25 27 76 41 113 26 68 26 68 4
65 -16 -2 -25 -15 -35 -52 -16 -54 -24 -52 -42 12 -10 37 -15 42 -41 42 -17 0
-30 -1 -30 -2z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
